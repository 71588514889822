import { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, buttonRef, id, method, setEditing) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        let input = ref.current.value;
        if (!buttonRef.current.contains(event.target)) {
          setEditing(false);
        }
        if (id === "roomCode") {
          // console.log("You clicked outside of " + id);
          // console.log("Value: " + input);
          method(""); // Call join room to make room input invisible
        } else if (id === "username") {
          if (input === "") {
            let lastUsername = localStorage.getItem("username");
            method(lastUsername); // Call setValue to ensure username has an input
          }
        }
      }
      // if clicked on the input
      else if (ref.current) {
        setEditing(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, id, method, setEditing]);
}

export default function Input(props) {
  const {
    placeholder,
    type,
    maximum,
    id,
    display,
    value,
    setValue,
    focus,
    method,
    bare,
    onLoseFocus,
  } = props;

  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  useOutsideAlerter(
    inputRef,
    buttonRef,
    id,
    id === "roomCode" ? method : setValue,
    setEditing
  );

  const handleChange = (event) => {
    let inputType = typeof event.target.value;
    let input = null;

    if (inputType === "string") {
      input =
        event.target.value.length <= maximum
          ? event.target.value.toUpperCase()
          : event.target.value.substring(0, maximum).toUpperCase();
    } else if (inputType === "number") {
      input =
        String(event.target.value).length <= maximum
          ? event.target.value
          : Number(String(event.target.value).substring(0, maximum));
    }

    if (id === "username" && input !== "") {
      localStorage.setItem("username", input);
    }
    setValue(input);
    if (id === "roomCode" && String(event.target.value).length === maximum) {
      method(input);
    }
  };

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    } else {
      window.focus();
      if (onLoseFocus) {
        onLoseFocus();
      }
    }
  }, [editing]);

  return (
    <div
      className={`box-border ${
        display === "none" ? "hidden" : "relative"
      } ${bare ? "max-w-48" : "max-w-96"} gap-2 bg-black/50 backdrop-blur flex justify-center align-center items-center w-full rounded-md shadow-md shadow-black/50 p-2  border-4 border-black/50`}
    >
      <input
        tabIndex={0}
        ref={inputRef}
        className={`${
          bare ? "rounded-xl" : "rounded-xl  "
        } box-border  shadow-inner text-4xl  w-full h-16 shadow-black bg-black/30  border-black/50 transition-all select-none text-neutral-300 text-center select-none outline-none placeholder:text-neutral-300/40`}
        placeholder={placeholder}
        type={type}
        onChange={handleChange}
        value={value}
        inputMode={type === "number" ? "numeric" : "text"}
        autoCapitalize="characters"
        autoComplete="off"
        // inputProps={{ inputMode: 'numeric' }}
      />
      <button
        ref={buttonRef}
        onClick={() => setEditing(!editing)}
        className={` ${
          bare ? "hidden" : "relative"
        } h-16 w-16 bg-gradient-to-tr ${editing ? "from-neutral-500 via-neutral-700 to-neutral-600": "from-neutral-400 via-neutral-600 to-neutral-500"}  relative border-4 border-black/50 rounded-xl shrink-0 text-4xl flex items-center justify-center text-neutral-950/90 transition-all `}
      >
        <div className={` w-full h-full overflow-hidden absolute `}>
          <div
            className={`-top-3 ${editing ? "right-6" : "right-7"}  w-[25%] h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
          ></div>
          <div
            className={`-top-3 ${editing ? "right-2" : "right-3"}  w-[10%] h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
          ></div>
        </div>
        <FontAwesomeIcon
          icon={faPencil}
          className={`${editing ? "left-[50%] -translate-x-[43%]" : "left-[50%] -translate-x-[50%]"} absolute transition-all duration-500`}
        />
        {/* <FontAwesomeIcon
          icon={faCircleCheck}
          className={`${!editing ? "opacity-0" : "opacity-100"} absolute transition-all duration-300`}
        /> */}
        <div
          className={`${
            editing
              ? "shadow-[0_0_0_0_rgb(0,0,0,0.1),inset_0_2px_4px_3px_rgb(0,0,0,0.05),0_0_0_0_rgb(0,0,0,0.1)]"
              : "shadow-[0_4px_6px_-1px_rgb(0,0,0,0.1),inset_0_0_0_0_rgb(0,0,0,0.05),0_2px_4px_-2px_rgb(0,0,0,0.1)]"
          } shadow-black w-16 h-16 absolute rounded-xl transition-shadow duration-500`}
        ></div>
      </button>
    </div>
  );
}
