import "./App.css";
import Background from "./components/Background.js";
import Longship from "./components/Longship.js";
import Notification from "./components/Notification.js";
import Textplate from "./components/Textplate.js";
import Waves from "./components/Waves.js";
import Game from "./pages/Game.js";
import Home from "./pages/Home.js";
import Intro from "./pages/Intro.js";
import Landscape from "./pages/Landscape.js";
import Lobby from "./pages/Lobby.js";
import Main from "./pages/Main.js";
import Portrait from "./pages/Portrait.js";
import { socket } from "./socket";
import { useCallback, useEffect, useState } from "react";

function App() {
  const debugMode = false;

  const [room, setRoom] = useState(null);
  const [host, setHost] = useState(null);
  const [gameState, setGameState] = useState(null);
  const [customization, setCustomization] = useState({
    card: localStorage.getItem("card")
      ? localStorage.getItem("card")
      : "1",
    icon: localStorage.getItem("icon")
      ? localStorage.getItem("icon")
      : "1",
  });
  const [role, setRole] = useState(null);
  const [teammates, setTeammates] = useState([]);
  const [info, setInfo] = useState(null);
  // const [votedRoles, setVotedRoles] = useState(null);
  // const [votedUsers, setVotedUsers] = useState(null);
  const [timeLimits, setTimeLimits] = useState(null);
  const [settings, setSettings] = useState(null);

  const [entrancePhase, setEntrancePhase] = useState(-1);
  const [entrancePhaseCount, setEntrancePhaseCount] = useState(1);
  const [entranceExiles, setEntranceExiles] = useState([]);
  const [entranceDead, setEntranceDead] = useState([]);
  const [entranceCooldown, setEntranceCooldown] = useState(0);

  const [data, setData] = useState(null);

  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result.toUpperCase();
  }

  const [username, setUsername] = useState(
    debugMode
      ? makeid(3)
      : localStorage.getItem("username") == null
      ? "GUEST"
      : localStorage.getItem("username")
  );

  const [user, setUser] = useState({"username":username, "customization":customization})
  const [users, setUsers] = useState(user);

  const updateCustomization = useCallback((newCustomization) => {
    setCustomization(newCustomization);
    setUser({"username":username, "customization":newCustomization});
    console.log("Updating customization.")
    console.log(room)
    if (room) {
      socket.emit("update_customization", {username, newCustomization, room});
      console.log("Updating customization for room.")
    }
  }, [username, room])

  const updateUsername = useCallback((newUsername) => {
    setUsername(newUsername);
    setUser({"username":newUsername, "customization":customization});
  }, [customization])


  // const getCookie = useCallback((cname) => {
  //   let name = cname + "=";
  //   let decodedCookie = decodeURIComponent(document.cookie);
  //   let ca = decodedCookie.split(";");
  //   for (let i = 0; i < ca.length; i++) {
  //     let c = ca[i];
  //     while (c.charAt(0) === " ") {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) === 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return "";
  // }, []);

  const userJoin = useCallback(
    (username, roomCode, users, host) => {
      console.log("userJoin", username);
      console.log(users);
      console.log("room", room);
      setUsers(users);
      if (!room) {
        setRoom(roomCode);
        console.log("setroom", roomCode);

        //if this client isnt already in the room, set everything, otherwise only update users
        setUsername(username);
        setHost(host);
        setGameState("lobby");
      }
    },
    [room]
  );

  const userReconnect = useCallback(
    (
      user,
      roomCode,
      users,
      host,
      gameState,
      settings,
      // timeLimits,
      phase,
      phaseCount,
      // teammates,
      // entranceExiles,
      // entranceDead,
      // currentCooldown
    ) => {
      console.log("userReconnect", user);
      setUsername(user["username"]);
      setHost(host);
      setRole(user["role"]);
      setRoom(roomCode);
      // setTimeLimits(timeLimits);
      setUsers(users);
      setEntrancePhase(phase);
      setEntrancePhaseCount(phaseCount);
      setSettings(settings);
      // setTeammates(teammates);
      // setEntranceExiles(entranceExiles);
      // setEntranceDead(entranceDead);
      // setEntranceCooldown(currentCooldown);
      setGameState(gameState);
    },
    []
  );

  const userLeave = useCallback(
    (user, users) => {
      console.log("userLeave", user);
      if (user === username) {
        //if this client is leaving
        setRoom(null);
        setUsers(null);
        setHost(null);
        setGameState(null);
      } else {
        setUsers(users);
        // if (users[0]["username"] !== host) {
        //   setHost(users[0]["username"]);
        //   console.log("Host changed from " + user + " to " + username + ".");
        // }
      }
    },
    [username]
  );

  const changeHost = useCallback((newHost) => {
    console.log("changeHost", newHost);
    // console.log("Host changed from ", host, " to ", newHost)
    setHost(newHost);
  }, []);

  const gameStart = useCallback(
    (settings) => {
      console.log("gameStart");
      setSettings(settings);
      setGameState("game");
      localStorage.setItem("inGame", room);
    },
    [room]
  );

  const gameEnd = useCallback(() => {
    console.log("gameEnd");
    setGameState("end");
    localStorage.setItem("inGame", null);
  }, []);

  const getRole = useCallback((role) => {
    console.log("getRole", Object.values(role));
    setRole(role);
  }, []);

  const getInfo = useCallback((info) => {
    console.log("getInfo");
    setInfo(info);
  }, []);

  // const getOrder = useCallback((order) => {
  //   setOrder(order);
  // }, []);

  // const getOverview = useCallback((dict) => {
  //   console.log("Get Overview")
  //   setVotedRoles(dict["votes"]);
  //   setFinalRoles(dict["roles"]);
  //   setVotedUsers(dict["users"]);
  //   console.log(dict["roles"][username]);
  //   setFinalRole(dict["roles"][username])
  // }, [username])

  const toLobby = useCallback(() => {
    console.log("toLobby");
    setGameState("lobby");
    setTeammates([]);
    setEntrancePhase(-1);
    setEntrancePhaseCount(1);
    localStorage.setItem("inGame", null);
  }, []);

  const getTeammates = useCallback((teammates) => {
    console.log("getTeammates", teammates);
    setTeammates(teammates);
  }, []);

  const getData = useCallback((roles, items) => {
    console.log("getData");
    // setRoles(roles);
    setData({roles, items});
  }, []);

  const updateUsers = useCallback((users) => {
    setUsers(users);
    console.log(users);
  }, []);

  useEffect(() => {
    socket.on("room_create", userJoin);
    socket.on("user_leave", userLeave);
    socket.on("user_join", userJoin);
    socket.on("game_start", gameStart);
    socket.on("game_end", gameEnd);
    socket.on("get_role", getRole);
    socket.on("get_teammates", getTeammates);
    // socket.on("get_initial_role", getInitialRole);
    // socket.on("get_location", getLocation);
    socket.on("update_users", updateUsers);
    socket.on("get_info", getInfo);
    // socket.on("get_order", getOrder);
    // socket.on("overview", getOverview);
    socket.on("to_lobby", toLobby);
    socket.on("data", getData);
    socket.on("user_reconnect", userReconnect);
    socket.on("change_host", changeHost);

    return () => {
      socket.off("room_create", userJoin);
      socket.off("user_leave", userLeave);
      socket.off("user_join", userJoin);
      socket.off("game_start", gameStart);
      socket.off("game_end", gameEnd);
      // socket.off("get_initial_role", getInitialRole);
      // socket.off("get_location", getLocation);
      socket.off("update_users", updateUsers);
      socket.off("get_info", getInfo);
      // socket.off("get_order", getOrder);
      // socket.off("overview", getOverview);
      socket.off("to_lobby", toLobby);
      socket.off("get_role", getRole);
      socket.off("get_teammates", getTeammates);
      socket.off("data", getData);
      socket.off("user_reconnect", userReconnect);
      socket.off("change_host", changeHost);
    };
  }, [
    userJoin,
    userLeave,
    gameStart,
    gameEnd,
    getInfo,
    getRole,
    getData,
    getTeammates,
    toLobby,
    userReconnect,
    changeHost,
    updateUsers,
  ]);

  useEffect(() => {
    console.log("Connecting.");
    socket.connect();
    return () => {
      console.log("Disconnecting.");
      socket.disconnect();
    };
  }, []);

  // useEffect(() => {
    //  Reconnecting --- TRY MOVING AFTER SOCKET CONNECT /\
  //   if (localStorage.getItem("inGame") !== null) {
  //     let disconnectedRoom = localStorage.getItem("inGame");
  //     socket.emit("join", {
  //       username: username,
  //       room: disconnectedRoom,
  //       customization: { card: null, icon: null },
  //     });
  //   }

  // }, [username]);

  useEffect(() => { 
    socket.emit("data");
    console.log("Getting data.")
  }, [])

  useEffect(() => { 
    console.log("room changed:", room)
  }, [room])

  // const getOrientation = () => window.screen.orientation.type;

  // const [orientation, setOrientation] = useState(getOrientation());

  // const updateOrientation = useCallback((event) => {
  //   let newOrientation = getOrientation();

  //   setOrientation(
  //     newOrientation === "landscape-primary" ||
  //       newOrientation === "landscape-secondary"
  //       ? "landscape"
  //       : "portrait"
  //   );
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("orientationchange", updateOrientation);
  //   updateOrientation();
  //   return () => {
  //     window.removeEventListener("orientationchange", updateOrientation);
  //   };
  // }, [updateOrientation]);

  // const changeUsername = useCallback((newUsername) => {
  //   setUsername(newUsername);
  //   localStorage.setItem("username", newUsername);
  // }, []);

  return (
    <div className="w-dvh h-dvh  flex flex-col items-center justify-center overflow-hidden relative select-none">
      <div className={`w-full h-full z-50 absolute hidden portrait:lg:flex bg-main bg-cover items-center justify-center`}>
        
      <img
          src={require(`./images/cq-logo-min-h.png`)}
          alt=""
          className={`w-full max-w-[512px] top-1 h-auto absolute object-contain`}
          // ref={logoRef}
        ></img>
        <Textplate>MOBILE ONLY</Textplate>
      </div>
      {
        gameState ? (
          gameState === "lobby" ? (
            <Lobby
              username={username}
              room={room}
              users={users}
              host={host}
              user={user}
              data={data}
              setCustomization={updateCustomization}
            />
          ) : (
            <Game
              debugMode={debugMode}
              username={username}
              room={room}
              users={users}
              user={user}
              host={host}
              // role={role}
              // visibleTeammates={teammates}
              info={info}
              settings={settings}
              data={data}
              entrancePhase={entrancePhase}
              entrancePhaseCount={entrancePhaseCount}
              // entranceDead={entranceDead}
              // entranceExiles={entranceExiles}
              entranceCooldown={entranceCooldown}
            />
          )
        ) : (
          <Main
            setCustomization={updateCustomization}
            setUsername={updateUsername}
            user={user}
            data={data}
          ></Main>
          // <div>
          //   <Portrait
          //     debug={debugMode}
          //     username={username}
          //     customization={customization}
          //     // display={orientation === "portrait" ? "flex" : "hidden"}
          //   />
          //   <Landscape
          //     username={username}
          //     users={users}
          //     host={host}
          //     debug={debugMode}
          //     customization={customization}
          //     setCustomization={setCustomization}
          //   ></Landscape>
          // </div>
        )

        // username === "" || username === null ? (

        //   <Intro changeUsername={changeUsername} orientation={orientation} />
        // ) : (
        //   <div>
        //     <Portrait
        //       debug={debugMode}
        //       username={username}
        //       // display={orientation === "portrait" ? "flex" : "hidden"}
        //       setShield={setShield}
        //     />
        //     <Landscape users={users} host={host} shield={shield} debug={debugMode}></Landscape>
        //   </div>
        // )
      }

      <Notification></Notification>
    </div>
  );
}

export default App;
