import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  faAddressBook,
  faAddressCard,
  faGem,
  faMasksTheater,
} from "@fortawesome/free-solid-svg-icons";
import Selector from "./Selector";
import Choice from "./Choice";
import Player from "./Player";
import Input from "./Input";
import Role from "./Role";
import { gsap } from "gsap";
import Item from "./Item";

export default function Information(props) {
  const { user, data, setCustomization, setUsername, page, clickFunction=null } = props;

  const [subpage, setSubpage] = useState(page === "main" ? 0 : page === "game" ? 2 : 1);
  const [viewedRole, setViewedRole] = useState(0);
  const [viewedItem, setViewedItem] = useState(0);
  const [card, setCard] = useState(parseInt(user["customization"]["card"]));
  const [icon, setIcon] = useState(parseInt(user["customization"]["icon"]));
  const [usernameInput, setUsernameInput] = useState(user["username"]);

  useEffect(() => {
    console.log(card);
  }, [card]);
  useEffect(() => {
    console.log(icon);
  }, [icon]);
  useEffect(() => {
    console.log(data);
  }, []);

  const changeCard = useCallback(
    (newCard) => {
      setCard(newCard);
      localStorage.setItem("card", newCard);
      setCustomization({ card: newCard, icon });
    },
    [icon, setCustomization]
  );

  const changeIcon = useCallback(
    (newIcon) => {
      setIcon(newIcon);
      localStorage.setItem("icon", newIcon);
      setCustomization({ card, icon: newIcon });
    },
    [card, setCustomization]
  );

  const changeUsername = useCallback(
    (newUsername) => {
      console.log("new u");
      setUsernameInput(newUsername);
      localStorage.setItem("username", newUsername);
      setUsername(newUsername);
    },
    [setUsername]
  );

  return (
    <div
      className={`landscape:flex portrait:hidden
          w-full h-full  flex-row items-center justify-start  relative select-none bg-quarters bg-cover bg-bottom`}
    >
      <div
        className={` flex w-[15%] h-full flex-col justify-center items-center gap-5 p-1 bottom-0 left-0 bg-black/50  border-r-4 border-neutral-700/25 overflow-y-scroll`}
      >
        {page === "main" ? (
          <Button
            type="icon"
            selected={subpage === 0}
            clickFunction={() => setSubpage(0)}
          >
            <FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon>
          </Button>
        ) : null}

        {page !== "game" ? (
          <Button
            type="icon"
            selected={subpage === 1}
            clickFunction={() => setSubpage(1)}
          >
            <FontAwesomeIcon icon={faMasksTheater}></FontAwesomeIcon>
          </Button>
        ) : null}

        <Button
          type="icon"
          selected={subpage === 2}
          clickFunction={() => setSubpage(2)}
        >
          <FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon>
        </Button>
        <Button
          type="icon"
          selected={subpage === 3}
          clickFunction={() => setSubpage(3)}
        >
          <FontAwesomeIcon icon={faGem}></FontAwesomeIcon>
        </Button>
      </div>
      {/* <Input
  placeholder="NAME"
  type="text"
  maximum={10}
  id="username"
  value={usernameInput}
  setValue={setUsernameInput}
/> */}
      <div
        className={` flex flex-row justify-center overflow-hidden items-center gap-1 w-[85%] ${
          subpage === 0 ? "flex" : "hidden"
        }`}
      >
        <Input
          placeholder={"Username"}
          type="text"
          maximum={10}
          id="username"
          value={usernameInput}
          setValue={changeUsername}
        ></Input>
      </div>

      <div
        className={` flex flex-row justify-center overflow-hidden items-center w-[85%] ${
          subpage === 1 ? "flex" : "hidden"
        }`}
      >
        <div
          className={` flex-col justify-center overflow-hidden items-end gap-1 w-[20%]  flex`}
        >
          {[1, 2, 3].map((id) => {
            return (
              <Choice
                id={id}
                clickFunction={() => changeIcon(id)}
                currentSelection={icon}
                type={"icon"}
              ></Choice>
            );
          })}
        </div>

        <div
          className={`flex flex-col justify-center overflow-hidden items-center gap-1 w-[40%]`}
        >
          {[1, 2, 3].map((id) => {
            return (
              <Choice
                id={id}
                clickFunction={() => changeCard(id)}
                currentSelection={card}
              ></Choice>
            );
          })}
        </div>

        <div
          className={`flex justify-around flex-col items-center overflow-hidden gap-1 h-full w-[40%]`}
        >
          <div className={`flex justify-center items-center`}>
            <Player user={user}></Player>
          </div>
        </div>
      </div>

      <div
        className={` flex flex-row justify-center items-center overflow-hidden gap-1 w-[85%] ${
          subpage === 2 ? "flex" : "hidden"
        }`}
      >
        <Selector
          direction={"left"}
          clickFunction={() =>
            setViewedRole(
              viewedRole === 0
                ? Object.keys(data["roles"]).length - 1
                : viewedRole - 1
            )
          }
        ></Selector>
        {data &&
          Object.values(data["roles"]).map((role, index) => {
            return (
              <Role
                role={role}
                visible={viewedRole === index}
                key={index}
                highlight={page === "game" && user["role"]["name"] === role["name"]}
              ></Role>
            );
          })}

        <Selector
          direction={"right"}
          clickFunction={() =>
            setViewedRole(
              viewedRole === Object.keys(data["roles"]).length - 1
                ? 0
                : viewedRole + 1
            )
          }
        ></Selector>
        {page === "game" && user["host"] ? <Button clickFunction={clickFunction}>End</Button> : null}
      </div>

      <div
        className={` flex flex-row justify-center items-center overflow-hidden gap-1 w-[85%] ${
          subpage === 3 ? "flex" : "hidden"
        }`}
      >
        <Selector
          direction={"left"}
          clickFunction={() =>
            setViewedItem(
              viewedItem === 0
                ? Object.keys(data["items"]).length - 1
                : viewedItem - 1
            )
          }
        ></Selector>
        {data &&
          Object.values(data["items"]).map((item, index) => {
            return (
              <Item
                item={item}
                visible={viewedItem === index}
                key={index}
              ></Item>
            );
          })}

        <Selector
          direction={"right"}
          clickFunction={() =>
            setViewedItem(
              viewedItem === Object.keys(data["items"]).length - 1
                ? 0
                : viewedItem + 1
            )
          }
        ></Selector>
      </div>
    </div>
  );
}
