import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import {
  faAngleLeft,
  faAngleRight,
  faCaretLeft,
  faCaretRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import gsap from "gsap";
import { useEffect, useRef } from "react";

export default function Selector(props) {
  const { clickFunction, direction, active = true } = props;

  const ref = useRef(null);

  useEffect(() => {
    console.log("active : ", active)
    if (active) {
      gsap.fromTo(
        ref.current,
        { x: 0, opacity: 0.5 },
        {
          x: direction === "left" ? -5 : 5,
          opacity: 1,
          duration: 1.5,
          repeat: -1,
          yoyo: true,
          yoyoEase: true,
        }
      );

    }
  }, [ref, active, direction]);

  return (
    <div
      ref={ref}
      className={`
        
        ${direction === "left" ? "justify-end  " : "justify-start"}
        ${
          active ? "visible" : "invisible"
        } w-[50px] h-[300px]  relative overflow-hidden   text-neutral-300/75  flex  items-center text-[50px] p-5 `}
      onClick={active ? clickFunction : null}
    >
      {/* <img
        alt=""
        src={require(`../images/materials/sandstone.jpg`)}
        className={`object-cover object-bottom-center  overflow-hidden absolute rounded w-full h-full top-0 leading-relaxed  brightness-[.5]    `}
      ></img> */}

      {/* <div
        className={`w-full h-full  absolute flex items-center justify-center outline outline-4 -outline-offset-4 outline-black/50 ${
          direction === "left" ? "rounded-l-full pl-5" : "rounded-r-full pr-5"
        } text-orange-200/75 text-[75px] `}
      > */}
      <FontAwesomeIcon
        icon={direction === "left" ? faAngleLeft : faAngleRight}
      ></FontAwesomeIcon>
      {/* </div> */}
    </div>
  );
}
