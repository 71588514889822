import { gsap } from "gsap/gsap-core";
import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";

export default function Item(props) {
  const { children, visible = true, clickFunction, item } = props;

  const ref = useRef(null);
  
  // ,

  //   "6": {
  //     "name": "Bag of Sand",
  //     "rarity": 3,
  //     "image": "elixir.jpg",
  //     "description": "Swap this with someone's rarest item.",
  //     "targetType": "item",
  //     "itemTargetType": "crate",
  //     "interaction": "swap"
  //   },

  //   "7": {
  //     "name": "Bag of Sand",
  //     "rarity": 2,
  //     "image": "elixir.jpg",
  //     "description": "Steal someone's least rare item.",
  //     "targetType": "item",
  //     "itemTargetType": "player",
  //     "interaction": "steal"
  //   }

  useGSAP(
    () => {
      gsap.fromTo(
        ref.current,
        {
          rotateZ: -1,
        },
        {
          rotateZ: 1,
          duration: 5,
          repeat: -1,
          yoyo: true,
          yoyoEase: true,
        }
      );

      gsap.fromTo(
        ref.current,
        {
          y: -5,
        },
        {
          y: 5,
          duration: 3,
          repeat: -1,
          yoyo: true,
          yoyoEase: true,
        }
      );
    },
    { dependencies: [ref] }
  );

  useGSAP(
    () => {
      if (visible) {
        gsap.fromTo(
          ref.current,
          {
            opacity: 0,
            y: 15,
          },
          { opacity: 1, y: 0, duration: 1 }
        );
      }
    },
    { dependencies: [ref, visible] }
  );

  const rarityShadows = {
    "-1": "drop-shadow-[0px_0px_30px_#facc15]",
    0: "drop-shadow-[0px_0px_30px_#fafafa]",
    1: "drop-shadow-[0px_0px_30px_#0ea5e9]",
    2: "drop-shadow-[0px_0px_30px_#7c3aed]",
  };

  return (
    <div
      //   style={style}
      ref={ref}
      className={`w-[200px] h-[250px] max-h-[100%] relative 
       ${
         visible ? "flex" : "hidden"
       } items-center justify-center text-white    bg-neutral-400 
       ${rarityShadows[item["rarity"]]}
      text-center  rounded`}
      onClick={clickFunction}
    >
      <img
        alt=""
        src={require(`../images/items/${
          item["rarity"] === -1 ? "jewel.jpg" : item["rarity"] === 0 ? "chest-small.jpg" : item["rarity"] === 1 ? "chest-medium.jpg" : item["rarity"] === 2 ? "chest-large.jpg" : null
        }`)}
        className={`object-cover object-bottom-center  overflow-hidden  w-full h-full top-0 leading-relaxed  rounded  `}
      ></img>

      <div
        className={`w-full h-full absolute border-4 border-black/50 rounded`}
      ></div>

      <div
        className={`w-[90%] h-[50px]  absolute -top-[35px] left-1/2 -translate-x-1/2  flex items-center justify-center shadow-black shadow-md rounded`}
      >
        <img
          alt=""
          src={require(`../images/materials/sandstone.jpg`)}
          className={`object-cover object-bottom-center  overflow-hidden absolute rounded w-full h-full top-0 leading-relaxed  brightness-[.5]    `}
        ></img>

        <div
          className={`w-full h-full  absolute flex items-center justify-center border-4 border-black/25`}
        >
          {item["name"]}
        </div>
      </div>

      {item["description"] ? (
        <div
          className={`w-[90%] h-[40%]  absolute bottom-2 left-1/2 -translate-x-1/2 bg-black/50   flex items-center justify-center shadow-black shadow rounded px-5 backdrop-blur-[1px]`}
        >
          {item["description"]}
        </div>
      ) : null}

      {/* <div
        className={`w-full h-full bg-black absolute`}
        style={style}
      ></div> */}
    </div>
  );
}
