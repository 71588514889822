import { useCallback, useEffect, useState } from "react";
import { socket } from "../socket";
import Button from "../components/Button";
import Textplate from "../components/Textplate";
import Player from "../components/Player";
import Role from "../components/Role";
import {
  faBox,
  faBoxOpen,
  faCaretLeft,
  faCaretRight,
  faEye,
  faEyeSlash,
  faGem,
  faLock,
  faLockOpen,
  faSackXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionButton from "../components/ActionButton";
import Selector from "../components/Selector";
import Item from "../components/Item";
import Information from "../components/Information";

export default function Game(props) {
  const {
    debugMode,
    username,
    room,
    // user,
    users,
    host,
    // role,
    // visibleTeammates = [],
    settings,
    data,
    entrancePhase = -1,
    entrancePhaseCount = 1,
    // entranceDead = [],
    // entranceExiles = [],
    entranceCooldown = 0,
  } = props;

  const [time, setTime] = useState(settings["phaseTimeLimit"]);
  // const [targetedPlayers, setTargetedPlayers] = useState([null]);
  // const [votedPlayers, setVotedPlayers] = useState([]);
  // const [roleGuess, setRoleGuess] = useState(null);

  // const [usernames, setUsernames] = useState(
  //   users.map((user) => {
  //     return user["username"];
  //   })
  // );

  const [user, setUser] = useState(
    users.find((user) => {
      return user["username"] === username;
    })
  );

  const [role, setRole] = useState(
    users.find((user) => {
      return user["username"] === username;
    })["role"]
  );

  const [phase, setPhase] = useState(entrancePhase);
  const [cooldown, setCooldown] = useState(entranceCooldown);

  const [winner, setWinner] = useState(null);
  const [viewedRole, setViewedRole] = useState(0);

  const [ghostVoteUsed, setGhostVoteUsed] = useState(false);
  const [finalUsers, setFinalUsers] = useState([]);

  const [hideScreen, setHideScreen] = useState(false);
  const [lockedIn, setLockedIn] = useState(false);
  const [readiedUp, setReadiedUp] = useState(false);

  const [history, setHistory] = useState([]);
  const [phaseCount, setPhaseCount] = useState(entrancePhaseCount);
  const [viewedPhase, setViewedPhase] = useState(entrancePhaseCount);

  const [lockedInUsernames, setLockedInUsernames] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [viewedItemIndex, setViewedItemIndex] = useState(0);
  const [viewedCrateItemIndex, setViewedCrateItemIndex] = useState(0);
  const [viewedPlayerIndex, setViewedPlayerIndex] = useState(0);

  const [currentCaptain, setCurrentCaptain] = useState(
    users.find((u) => {
      return u["rank"] === 1;
    })
  );

  const [currentFirstMate, setCurrentFirstMate] = useState(
    users.find((u) => {
      return u["rank"] === 2;
    })
    // users.find((u) => {
    //   return u["rank"] === "firstMate";
    // })
  );

  const [expeditionItems, setExpeditionItems] = useState(null);
  const [treasureChest, setTreasureChest] = useState([]);
  const [viewingTreasureChest, setViewingTreasureChest] = useState(false);
  const [viewingItems, setViewingItems] = useState(false);
  const [viewingPocket, setViewingPocket] = useState(true);

  const [pocketedItems, setPocketedItems] = useState([]);
  const [choosingTargets, setChoosingTargets] = useState(false);
  const [choosingTreasureChestItem, setChoosingTreasureChestItem] =
    useState(false);

  // const [usedItemsIndeces, setUsedItemsIndeces] = useState([]);
  // const [usedItemTargetUsernames, setUsedItemTargetUsernames] = useState([]);
  const [activatedItems, setActivatedItems] = useState([]);
  const [viewedItem, setViewedItem] = useState(null);

  const [endOfPhase, setEndOfPhase] = useState(false);
  // const [expeditionPicks, setExpeditionPicks] = useState(0);

  const [timer, setTimer] = useState();

  // const [actionButtonPage, setActionButtonPage] = useState(0);
  const updateUsers = useCallback(() => {
    console.log("updateUsers : ", users);
    console.log("phase ", phase);
    if (phase === 0) {
      console.log("setting captain firstMate");
      setCurrentCaptain(
        users.find((u) => {
          return u["rank"] === 1;
        })
      );

      setCurrentFirstMate(
        users.find((u) => {
          return u["rank"] === 2;
        })
      );
    } else {
      setPocketedItems(user["items"]);
    }
  }, [phase, users, user]);

  useEffect(() => {
    console.log("users: ", users);
    setUser(
      users.find((u) => {
        return u["username"] === username;
      })
    );

    updateUsers();
    // setCurrentCaptain(
    //   users.find((u) => {
    //     return u["rank"] === "captain";
    //   })
    // );
    // setCurrentFirstMate(
    //   users.find((u) => {
    //     return u["rank"] === "firstMate";
    //   })
    // );
  }, [users, username, updateUsers]);

  useEffect(() => {
    if (pocketedItems.length === 0) {
      setViewedItem(null);
      console.log("setViewedItem : ", null);
    } else {
      setViewedItem(pocketedItems[viewedItemIndex]);
      console.log("setViewedItem : ", pocketedItems[viewedItemIndex]);
    }
  }, [viewedItemIndex, pocketedItems]);

  useEffect(() => {
    console.log("activatedItems : ", activatedItems);
  }, [activatedItems]);

  // useEffect(() => {
  //   console.log("user: ", user);

  //   console.log("pocketedItems : ", pocketedItems);
  //   console.log("user[items] : ", user["items"]);
  // }, [user, pocketedItems]);

  useEffect(() => {
    console.log("endOfPhase : ", endOfPhase);
    if (endOfPhase) {
      setViewingItems(false);
    }
  }, [endOfPhase]);

  const returnToLobby = useCallback(() => {
    console.log("returnToLobby");
    console.log("Returning to lobby.");
    socket.emit("lobby", { room });
  }, [room]);

  const vote = useCallback(() => {
    console.log("vote : ", selectedUsers);
    socket.emit("vote", { room, selectedUsers, username });
  }, [room, username, selectedUsers]);

  const action = useCallback(() => {
    console.log("action");

    let actions = [];
    let newPocketedItems = [...pocketedItems];

    for (let i = 0; i < activatedItems.length; i++) {
      actions.push(activatedItems[i]);
      newPocketedItems.splice(activatedItems[i]["index"], 1);
    }

    console.log(actions);

    socket.emit("action", {
      room,
      username,
      actions,
    });
  }, [
    pocketedItems,
    activatedItems,
    // usedItemsIndeces,
    // usedItemTargetUsernames,
    room,
    username,
  ]);

  const changeViewingPage = useCallback((page) => {
    if (page === "pocket") {
      setViewingPocket(true);
      setViewingTreasureChest(false);
    } else if (page === "chest") {
      setViewingPocket(false);
      setViewingTreasureChest(true);
    }
  }, []);

  const togglePocketedItem = useCallback(
    (item, target, index) => {
      console.log("togglePocketedItem : ", item, target, index);

      let newActivatedItems = [...activatedItems];

      // console.log(newActivatedItems);

      // console.log(viewedItemIndex)
      // console.log(newActivatedItems.some(i => i["index"] === viewedItemIndex))

      if (newActivatedItems.some((i) => i["index"] === index)) {
        console.log("Deactivated item.");
        newActivatedItems.splice(
          newActivatedItems.findIndex((i) => i["index"] === index),
          1
        );
      } else {
        console.log("Activated item.");
        newActivatedItems.push({ item, target, index });
      }
      setActivatedItems(newActivatedItems);

      // let i = usedItemsIndeces.indexOf({});
      // let newUsedItemsIndeces = [...usedItemsIndeces];
      // let newUsedItemTargetUsernames = [...usedItemTargetUsernames];

      // console.log(newUsedItemsIndeces);
      // console.log(newUsedItemTargetUsernames);

      // if (i === -1) {
      //   newUsedItemsIndeces.push(index);
      //   console.log("newUsedItemsIndeces : ", newUsedItemsIndeces);

      //   newUsedItemTargetUsernames.push(targetUsername);
      //   console.log(
      //     "newUsedItemTargetUsernames : ",
      //     newUsedItemTargetUsernames
      //   );
      // } else {
      //   console.log("Item is being cancelled.");
      //   newUsedItemsIndeces.splice(i, 1);
      //   newUsedItemTargetUsernames.splice(i, 1);
      //   console.log(newUsedItemsIndeces);
      //   console.log(newUsedItemTargetUsernames);
      // }

      // setUsedItemsIndeces(newUsedItemsIndeces);
      // setUsedItemTargetUsernames(newUsedItemTargetUsernames);
      setChoosingTargets(false);
      setChoosingTreasureChestItem(false);
    },
    [activatedItems]
  );

  const clickPlayer = useCallback(
    (user = null) => {
      console.log("clickPlayer : ", user["username"]);

      if (choosingTargets) {
        togglePocketedItem(
          pocketedItems[viewedItemIndex],
          user["username"],
          viewedItemIndex
        );
      } else {
        let currentUsers = [...selectedUsers];
        let index = currentUsers.indexOf(user);
        if (index === -1) {
          currentUsers.push(user);
          setSelectedUsers(currentUsers);
        } else {
          currentUsers.splice(index, 1);
          setSelectedUsers(currentUsers);
        }
      }
    },
    [
      selectedUsers,
      togglePocketedItem,
      choosingTargets,
      viewedItemIndex,
      pocketedItems,
    ]
  );

  const clickItem = useCallback(
    (item) => {
      console.log("clickItem : ", item);

      if (choosingTreasureChestItem) {
        togglePocketedItem(
          pocketedItems[viewedItemIndex],
          viewedCrateItemIndex,
          viewedItemIndex
        );
      }
    },
    [
      pocketedItems,
      viewedItemIndex,
      choosingTreasureChestItem,
      togglePocketedItem,
      viewedCrateItemIndex,
    ]
  );

  const pocketItems = useCallback(
    (index) => {
      console.log("pocketItems : ", expeditionItems[index]);

      setViewedItemIndex(0);
      let oldPocketedItems = [...pocketedItems];
      let items = [expeditionItems[index]];
      setPocketedItems(oldPocketedItems.concat(items));

      socket.emit("pocketItems", { room, username, items });
      setExpeditionItems(null);
    },
    [room, username, expeditionItems, pocketedItems]
  );

  const chooseTargets = useCallback(
    (item) => {
      console.log("chooseTargets : ", item);

      setChoosingTargets(choosingTargets ? false : true);
    },
    [choosingTargets]
  );

  const chooseTreasureChestItem = useCallback(
    (item) => {
      console.log("choosingTreasureChestItem : ", item);

      setChoosingTreasureChestItem(choosingTreasureChestItem ? false : true);
    },
    [choosingTreasureChestItem]
  );

  const lockIn = useCallback(() => {
    console.log("lockIn");
    setLockedIn(!lockedIn);
    socket.emit("lockIn", { room, username, debugMode });
  }, [room, username, lockedIn, debugMode]);

  // document.body.addEventListener('keypress', function(event) {
  //   var key = event.key
  //   if (key === " ") {
  //     lockIn();
  //   }
  // });

  const readyUp = useCallback(() => {
    console.log("readyUp");
    setReadiedUp(!readiedUp);
    socket.emit("readyUp", { room, username, debugMode });
  }, [room, username, readiedUp, debugMode]);

  const getLockedInUsernames = useCallback((lockedInUsernames) => {
    console.log("getLockedInUsernames");
    setLockedInUsernames(lockedInUsernames);
  }, []);

  const getExpeditioners = useCallback((expeditioners) => {
    console.log("getExpeditioners : ", expeditioners);
    setCurrentCaptain(expeditioners[0]);
    setCurrentFirstMate(expeditioners[1]);
    setEndOfPhase(true);
  }, []);

  const getExpeditionItems = useCallback((items) => {
    console.log("getExpeditionItems : ", items);
    setExpeditionItems(items);
  }, []);

  const updateTreasureChest = useCallback((newCrate) => {
    console.log("updateTreasureChest");
    setTreasureChest(newCrate);
    setEndOfPhase(true);
  }, []);

  useEffect(() => {
    console.log("pocketedItems : ", pocketedItems);
    console.log("viewedItemIndex : ", viewedItemIndex);
  }, [pocketedItems, viewedItemIndex]);

  const start_timer = useCallback(() => {
    console.log("start_timer");
    // console.log("starting timer.");

    let bellAudio = new Audio(require("../sounds/bell.mp3"));
    clearInterval(timer);
    setTimer(
      setInterval(() => {
        setTime((time) => {
          if (time === 0) {
            clearInterval(timer);
            return 0;
          } else if (time === 16) {
            // try {
            //   bellAudio.play();
            // } catch (e) {
            //   console.log(e);
            // }
            return time - 1;
          } else return time - 1;
        });
      }, 1000)
    );
  }, [timer]);

  // const addHistory = useCallback(() => {
  //   console.log("addHistory");
  //   let newHistory = {};

  //   for (let i = 0; i < users.length; i++) {
  //     let currentUser = users[i];
  //     // 0:DEAD 1:EXILED 2:INJURED 3:STAMPS
  //     newHistory[currentUser["username"]] = [
  //       currentUser["damage"],
  //       currentUser["exiled"],

  //       phase === "solar"
  //         ? [
  //             {
  //               name: !currentUser["exiled"] ? "voteOut" : "voteIn",
  //               active:
  //                 votedPlayers.indexOf(currentUser["username"]) === -1
  //                   ? false
  //                   : true,
  //             },
  //           ]
  //         : phase === "lunar"
  //         ? [
  //             {
  //               name: role["action"],
  //               active:
  //                 targetedPlayers.indexOf(currentUser["username"]) === -1
  //                   ? false
  //                   : true,
  //             },
  //           ]
  //         : null,
  //     ];
  //   }

  //   let oldHistory = [...history];
  //   oldHistory.push(newHistory);
  //   setHistory(oldHistory);
  // }, [
  //   history,
  //   targetedPlayers,
  //   votedPlayers,
  //   phase,
  //   role,
  //   users
  // ]);

  const changePhase = useCallback(
    (newPhase) => {
      console.log("changePhase : ", newPhase);

      clearInterval(timer);

      setEndOfPhase(true);
      setViewedItemIndex(0);
      // if (newPhase === 1) {
      //   vote();
      // }

      // setSelectedUsers({
      //   username: null,
      //   exiled: null,
      //   damage: null,
      //   customization: null,
      // });

      setTimeout(
        function () {
          setLockedIn(false);
          setLockedInUsernames([]);
          setEndOfPhase(false);
          setViewedCrateItemIndex(0);
          setActivatedItems([]);
          // setUsedItemsIndeces([]);
          // setUsedItemTargetUsernames([]);
          // addHistory();

          // let
          // for (let i = 0; i < users.length; i++) {
          //   let currentUser = users[i]

          // }

          if (phase === -1) {
            setPhase(newPhase);
            setTime(settings["phaseTimeLimit"]);
            start_timer();
          } else {
            // setViewedPhase(phaseCount + 1);
            setPhaseCount(phaseCount + 1);

            setPhase(newPhase);
            // setTime(timeLimits[0]);
            // if (newPhase !== 1) {
            //   start_timer();
            // }
            // setLatestDead([]);
            // setLatestExiles([]);
            // setLatestInfo([]);
            // setReturnedExiles([]);
            // setTargetedPlayers([null]);
            // setVotedPlayers([]);
            // setAssassinatedPlayers([null]);
            // setRoleGuess(null);
            // clickPlayer();
            setSelectedUsers([]);
          }
        },
        phase === -1 ? 0 : settings["transitionTimeLimit"] * 1000
      );
    },
    [
      // vote,
      // action,
      start_timer,
      settings,
      phase,
      phaseCount,
      timer,
      // addHistory,
      // clickPlayer,
    ]
  );

  const gameOver = useCallback((winner, finalUsers) => {
    console.log("gameOver : ", finalUsers);
    setFinalUsers(finalUsers);
    setWinner(winner);
    setHideScreen(false);
  }, []);

  useEffect(() => {
    console.log("pocketed items", pocketedItems);
  }, [pocketedItems]);

  useEffect(() => {
    socket.on("vote_now", vote);
    // socket.on("info", getInfo);
    // socket.on("learn", learn);
    // socket.on("exiles", getExiles);
    socket.on("phase", changePhase);
    socket.on("game_over", gameOver);
    socket.on("locked_in", getLockedInUsernames);
    socket.on("expedition_items", getExpeditionItems);
    socket.on("expeditioners", getExpeditioners);
    socket.on("treasure_chest", updateTreasureChest);
    socket.on("get_votes", vote);
    socket.on("get_actions", action);

    return () => {
      socket.off("vote_now", vote);
      // socket.off("info", getInfo);
      // socket.off("learn", learn);
      // socket.off("exiles", getExiles);
      socket.off("phase", changePhase);
      socket.off("game_over", gameOver);
      socket.off("locked_in", getLockedInUsernames);
      socket.off("expedition_items", getExpeditionItems);
      socket.off("expeditioners", getExpeditioners);
      socket.off("treasure_chest", updateTreasureChest);
      socket.off("get_votes", vote);
      socket.off("get_actions", action);
    };
  }, [
    vote,
    // getInfo,
    changePhase,
    gameOver,
    // getExiles,
    // learn,
    getLockedInUsernames,
    getExpeditionItems,
    updateTreasureChest,
    getExpeditioners,
    action,
  ]);

  // const targetUser = useCallback(
  //   (user, roleGuess = null) => {
  //     let index = targetedPlayers.indexOf(username);
  //     if (index !== -1) {
  //       // if username in the array
  //       let newTargets = [
  //         ...targetedPlayers.slice(0, index),
  //         ...targetedPlayers.slice(index + 1),
  //       ];
  //       setTargetedPlayers(newTargets.length === 0 ? [null] : newTargets);
  //       console.log("Untargeted ", username);
  //     } else {
  //       // if username not in the array
  //       if (username === null) {
  //         setTargetedPlayers([null]);
  //       } else {
  //         setRoleGuess(roleGuess);

  //         let newTargets = [...targetedPlayers];
  //         newTargets.push(username);
  //         if (newTargets.length > role["targets"]) {
  //           newTargets.shift();
  //         }
  //         if (newTargets.length > 1 && newTargets.indexOf(null) > -1) {
  //           let nullIndex = newTargets.indexOf(null);
  //           newTargets.splice(nullIndex, 1);
  //         }
  //         setTargetedPlayers(newTargets);
  //         console.log("Targeted ", username);
  //       }
  //     }

  //     setSelectedUsers({
  //       username: null,
  //       exiled: null,
  //       damage: null,
  //       customization: null,
  //     });
  //   },
  // (targetedUsername) => {
  //   console.log("Instant Action");
  //   socket.emit("instant_action", { room, targetedUsername, username, role });
  // if (targetedPlayers.indexOf(null) === -1) {
  //   setCooldown(role["cooldown"]);
  //   console.log("tp", targetedPlayers);
  //   console.log(targetedPlayers.indexOf(null));
  // } else if (cooldown > 0) {
  //   setCooldown(cooldown - 1);
  // }
  // },
  //   [targetedPlayers, role, username]
  // );

  // const votePlayer = useCallback(
  //   (username) => {
  //     let index = votedPlayers.indexOf(username);
  //     if (index !== -1) {
  //       // if username in the array
  //       setVotedPlayers([
  //         ...votedPlayers.slice(0, index),
  //         ...votedPlayers.slice(index + 1),
  //       ]);
  //       console.log("Unvoted ", username);
  //     } else {
  //       // if username not in the array
  //       setVotedPlayers([username]);
  //       console.log("Voted ", username);
  //     }

  //     setSelectedUsers({
  //       username: null,
  //       exiled: null,
  //       damage: null,
  //       customization: null,
  //     });
  //   },
  //   [votedPlayers]
  // );

  return (
    <div
      className={` flex flex-col items-center justify-start  w-screen h-full   select-none`}
    >
      <div
        className={`w-full h-full absolute bg-cover bg-center -z-10 ${
          phase === 0 ? "bg-vote" : phase === 1 ? "bg-expedition" : "bg-action"
          // viewedPhase % 2 === 0 ? "bg-game bg-bottom" : "bg-game bg-center"
        } 
        `}
      ></div>
      {/* UPPER */}

      <div
        className={`justify-around items-center flex-row h-[15%] w-full relative portrait:flex landscape:hidden bg-black/50 backdrop-blur-[2px]`}
      >
        <Player
          user={user}
          // highlight={true}
          clientUser={user}
          phase={phase}
          endOfPhase={endOfPhase}
        ></Player>
        {winner !== null && !endOfPhase ? (
          <Textplate hanging={true}>Game Over</Textplate>
        ) : (
          <Textplate hanging={true}>
            {phase === 0
              ? "Appoint"
              : phase === 1
              ? "pirating"
              : phase === 2
              ? "action"
              : "role"}
          </Textplate>
        )}
      </div>

      {/* MIDDLE */}
      <div
        className={`justify-start items-center ${
          winner !== null && !endOfPhase
            ? "hidden"
            : "portrait:flex landscape:hidden"
        } flex-col h-[70%] w-full relative `}
      >
        {/* SELECTED PLAYER */}
        {/* <div
          className={`${
            selectedUsers["username"] !== null
              ? "w-full h-full  flex flex-col justify-center items-center"
              : "hidden"
          }    absolute z-10`}
        >
          <div
            className={`w-full h-full bg-black absolute backdrop-blur bg-black/40 `}
            onClick={() => clickPlayer()}
            // onClick={() => clickPlayer()}
          ></div>
          {selectedUsers["username"] ? (
            <Player
              user={selectedUsers}
              clickFunction={() =>
                setSelectedUsers({
                  username: null,
                  exiled: null,
                  damage: null,
                  customization: null,
                })
              }
            ></Player>
          ) : null} */}

        {/* <Player
            customization={selectedUsers["customization"]}
            username={selectedUsers["username"]}
            stamps={[
              // {
              //   name: "assassinate",
              //   active: assassinatedPlayers.indexOf(selectedUsers) !== -1,
              // },
              phase === "lunar"
                ? {
                    name: role["action"],
                    active:
                      targetedPlayers.indexOf(selectedUsers["username"]) !== -1,
                  }
                : phase === "solar"
                ? !selectedUsers["exiled"]
                  ? {
                      name: "voteOut",
                      active:
                        votedPlayers.indexOf(selectedUsers["username"]) !== -1,
                    }
                  : {
                      name: "voteIn",
                      active:
                        votedPlayers.indexOf(selectedUsers["username"]) !== -1,
                    }
                : null,
            ]}
            damage={selectedUsers["damage"]}
            exiled={selectedUsers["exiled"]}
            selectFunction={() => clickPlayer(selectedUsers)}
            highlight={
              selectedUsers["username"] === username
                ? "yellow"
                : role["team"] === 0 &&
                  visibleTeammates.indexOf(selectedUsers["username"]) > -1
                ? "red"
                : role["team"] === 1 &&
                  visibleTeammates.indexOf(selectedUsers["username"]) > -1
                ? "blue"
                : null
            }
            state={"game"}
            lockedIn={lockedIn}
            lockedInVisual={
              lockedInUsernames.indexOf(selectedUsers["username"]) > -1
            }
          ></Player> */}

        {/* <div
            className={`w-full h-2/3  flex flex-col justify-start items-center pt-10 gap-1 overflow-y-scroll `}
          >
            <ActionButton
              text={
                phase === "lunar"
                  ? role["action"]
                  : phase === "solar"
                  ? selectedUsers["exiled"]
                    ? "Vote In"
                    : "Vote Out"
                  : null
              }
              action={
                phase === "lunar"
                  ? role["action"]
                  : phase === "solar"
                  ? selectedUsers["exiled"]
                    ? "voteIn"
                    : "voteOut"
                  : null
              }
              clickFunction={
                phase === "lunar"
                  ? role["action"] === "assassinate"
                    ? () => setActionButtonPage(1)
                    : () => targetUser(selectedUsers["username"])
                  : phase === "solar"
                  ? () => votePlayer(selectedUsers["username"])
                  : null
              }
              active={
                true
                // phase === "lunar"
                //   ? winner === null &&
                //     selectedUsers["username"] !== username &&
                //     user["damage"] < 2 &&
                //     !user["exiled"] &&
                //     (role["action"] === "resurrect"
                //       ? selectedUsers["damage"] == 2
                //       : currentDead.indexOf(selectedUsers) === -1) &&
                //     cooldown === 0 &&
                //     (role["action"] === "return"
                //       ? currentExiles.indexOf(selectedUsers) > -1
                //       : role["action"] === "exile"
                //       ? currentExiles.indexOf(selectedUsers) === -1
                //       : role["action"] === "heal"
                //       ? currentInjured.indexOf(selectedUsers) > -1
                //       : true)
                //   : phase === "solar"
                //   ? winner === null &&
                //     selectedUsers["username"] !== username &&
                //     (currentDead.indexOf(username) === -1 ||
                //       (currentDead.indexOf(username) > -1 && !ghostVoteUsed)) &&
                //     currentExiles.indexOf(username) === -1 &&
                //     currentDead.indexOf(selectedUsers) === -1
                //   : false
              }
              visible={actionButtonPage === 0}
            ></ActionButton>

            {role["team"] === 0 &&
            visibleTeammates.indexOf(selectedUsers["username"]) === -1 &&
            selectedUsers["username"] !== username
              ? Object.values(roles).map((role) => {
                  return role["team"] !== 0 ? (
                    <ActionButton
                      text={role["name"]}
                      action={"assassinate"}
                      clickFunction={() =>
                        targetUser(selectedUsers, role["name"])
                      }
                      visible={actionButtonPage === 1}
                    ></ActionButton>
                  ) : null;
                })
              : null}
          </div> */}
        {/* </div> */}

        {/* START PHASE */}
        <div
          className={`w-full h-full flex flex-col justify-center gap-5 items-center ${
            phase === -1 ? "flex" : "hidden"
          }`}
        >
          <div className={` justify-center items-center `}>
            <Role role={role} highlight={true}></Role>
          </div>
        </div>

        {/* COLLECTORS CRATE */}

        <div
          className={`${
            viewingItems || choosingTreasureChestItem ? "flex" : "hidden"
          } flex-col  overflow-y-scroll overflow-x-hidden w-full h-full justify-around items-center    `}
        >
          <div
            className={`flex flex-row h-[80%] w-full justify-around items-center gap-y-5`}
          >
            <Selector
              direction={"left"}
              clickFunction={viewingTreasureChest ? () =>
                setViewedCrateItemIndex(viewedCrateItemIndex - 1) : () => setViewedItemIndex(viewedItemIndex - 1)
              }
              active={ viewingTreasureChest ? viewedCrateItemIndex > 0 : viewedItemIndex > 0}
            ></Selector>
            <div className={`h-[80%] w-1/2 ${viewingPocket && !choosingTreasureChestItem ? "flex" : "hidden"} justify-center items-center`}>
              {pocketedItems && pocketedItems.length > 0
                ? pocketedItems.map((item, index) => {
                    return (
                      <Item
                        visible={
                          viewedItemIndex === index &&
                          !choosingTargets &&
                          !choosingTreasureChestItem
                        }
                        item={item}
                        key={index}
                      ></Item>
                    );
                  })
                :  (
                  <Textplate>No Items</Textplate>
                )}
            </div>

            <div
              className={` ${
                choosingTreasureChestItem ? "h-full" : "h-[80%]"
              } w-3/4 bg-crate bg-cover bg-center mx-5 ${
                viewingPocket && !choosingTreasureChestItem ? "hidden" : "flex"
              } items-center justify-center gap-5 shadow shadow-black relative overflow-hidden`}
            >
              <img
                alt=""
                src={require(`../images/materials/wood.jpg`)}
                className={`object-cover object-bottom-center  overflow-hidden absolute w-full h-full top-0 leading-relaxed  brightness-[.5]    `}
              ></img>

              <div
                className={`w-full h-full border-8 border-black/50 absolute flex justify-center items-center gap-3 `}
              >
                {treasureChest.map((item, index) => {
                  return (
                    <Item
                      item={item}
                      visible={viewedCrateItemIndex === index}
                      key={index}
                      clickFunction={
                        choosingTreasureChestItem
                          ? () => clickItem(treasureChest[viewedCrateItemIndex])
                          : null
                      }
                    ></Item>
                  );
                })}
              </div>
            </div>

            <Selector
              direction={"right"}
              clickFunction={viewingTreasureChest ? () =>
                setViewedCrateItemIndex(viewedCrateItemIndex + 1) : () => setViewedItemIndex(viewedItemIndex + 1)
              }
              active={viewingTreasureChest ? viewedCrateItemIndex < treasureChest.length - 1 : viewedItemIndex < pocketedItems.length - 1}
            ></Selector>
          </div>

          <div
            className={`${
              !choosingTreasureChestItem ? "flex" : "hidden"
            } h-[20%] w-full justify-center gap-5 items-center`}
          >
            <Button
              type="icon"
              selected={viewingPocket}
              clickFunction={() => changeViewingPage("pocket")}
            >
              <FontAwesomeIcon icon={faSackXmark}></FontAwesomeIcon>
            </Button>

            <Button
              type="icon"
              selected={viewingTreasureChest}
              clickFunction={() => changeViewingPage("chest")}
            >
              <FontAwesomeIcon icon={faGem}></FontAwesomeIcon>
            </Button>
          </div>
        </div>

        {/* PLAYER DISPLAY */}

        {/* vote page */}

        <div
          className={`${
            ((phase === 0 && !endOfPhase) ||
              (phase === 2 && choosingTargets)) &&
            !viewingItems
              ? "flex"
              : "hidden"
          } flex-row  overflow-y-scroll w-full h-full justify-around items-center gap-y-5  py-5 flex-wrap  `}
        >
          {users.map((currentUser, index) => {
            return currentUser["username"] !== username ? (
              <Player
                user={currentUser}
                clickFunction={() => clickPlayer(currentUser)}
                selected={selectedUsers.indexOf(currentUser) !== -1}
                phase={phase}
                endOfPhase={endOfPhase}
                key={index}
                clientUser={user}
              ></Player>
            ) : null;
          })}
        </div>

        <div
          className={` ${
            phase === 0 && endOfPhase ? "flex" : "hidden"
          } h-full w-full flex-col`}
        >
          <div className={`h-1/3 w-full flex justify-center items-center`}>
            {currentCaptain ? (
              <Player
                user={currentCaptain}
                rank={currentCaptain["rank"]}
                // highlight={currentCaptain["username"] === username}
                clientUser={user}
                showRank={true}
              ></Player>
            ) : null}
            {currentFirstMate ? (
              <Player
                user={currentFirstMate}
                rank={currentFirstMate["rank"]}
                // highlight={currentFirstMate["username"] === username}
                clientUser={user}
                showRank={true}
              ></Player>
            ) : null}
          </div>

          <div className={`h-2/3 w-full  flex flex-row`}>
            <div
              className={`h-full w-1/2  flex justify-start items-center flex-col overflow-y-scroll`}
            >
              <Textplate>Voted by</Textplate>
              {currentCaptain
                ? currentCaptain["votedBy"].map((votedByUsername, index) => {
                    return (
                      <Player
                        user={users.find((u) => {
                          return u["username"] === votedByUsername;
                        })}
                        phase={phase}
                        endOfPhase={endOfPhase}
                        key={index}
                        clientUser={user}
                      ></Player>
                    );
                  })
                : null}
            </div>

            <div
              className={`h-full w-1/2  flex justify-start items-center flex-col overflow-y-scroll`}
            >
              <Textplate>Voted by</Textplate>
              {currentFirstMate
                ? currentFirstMate["votedBy"].map((votedByUsername, index) => {
                    return (
                      <Player
                        user={users.find((u) => {
                          return u["username"] === votedByUsername;
                        })}
                        phase={phase}
                        endOfPhase={endOfPhase}
                        key={index}
                        clientUser={user}
                      ></Player>
                    );
                  })
                : null}
            </div>
          </div>
        </div>

        {/* expedition page */}

        <div
          className={`${
            phase === 1 && !viewingItems ? "flex" : "hidden"
          } flex-row  overflow-y-scroll w-full h-full justify-center items-start gap-y-5 py-5 flex-wrap  `}
        >
          <div className={`h-1/3 w-full flex justify-center items-center`}>
            {currentCaptain ? (
              <Player
                user={currentCaptain}
                // selected={selectedUsers.indexOf(currentCaptain) !== -1}
                // rank={currentCaptain["rank"]}
                clientUser={user}
                // highlight={currentCaptain["username"] === username}
                showRank={true}
              ></Player>
            ) : null}
            {currentFirstMate ? (
              <Player
                user={currentFirstMate}
                // rank={currentFirstMate["rank"]}
                // highlight={currentFirstMate["username"] === username}
                clientUser={user}
                showRank={true}
              ></Player>
            ) : null}
          </div>

          <div
            className={`h-1/3 w-full ${
              expeditionItems ? "flex" : "hidden"
            } justify-around items-center `}
          >
            {/* <div className={`w-[50px] h-[50px] bg-black ${viewedItemIndex > 0 ? "opacity-100" : "opacity-0"}`} onClick={viewedItemIndex > 0 ? () => setViewedItemIndex(viewedItemIndex - 1) : null}></div> */}
            <Selector
              direction={"left"}
              clickFunction={() => setViewedItemIndex(viewedItemIndex - 1)
              }
              active={viewedItemIndex > 0}
            ></Selector>
            {expeditionItems //&& ((expeditionItems.length === 3 && currentCaptain === user) || ((expeditionItems.length === 2 && currentFirstMate === user)) )
              ? expeditionItems.map((item, index) => {
                  return (
                    <Item
                      visible={viewedItemIndex === index}
                      item={item}
                      key={index}
                    ></Item>
                  );
                })
              : null}
            <Selector
              clickFunction={
                expeditionItems && viewedItemIndex < expeditionItems.length - 1
                  ? () => setViewedItemIndex(viewedItemIndex + 1)
                  : null
              }
              active={
                expeditionItems && viewedItemIndex < expeditionItems.length - 1
              }
            ></Selector>
            {/* <div className={`w-[50px] h-[50px] bg-black ${expeditionItems && viewedItemIndex < expeditionItems.length - 1 ? "opacity-100" : "opacity-0"}`} onClick={expeditionItems && viewedItemIndex < expeditionItems.length - 1 ? () => setViewedItemIndex(viewedItemIndex + 1) : null}></div> */}
          </div>

          {/* 
          {endOfPhase ? (
            <Selector
              direction={"left"}
              clickFunction={
                viewedItemIndex > 0
                  ? () => setViewedCrateItemIndex(viewedCrateItemIndex - 1)
                  : null
              }
              active={viewedCrateItemIndex > 0}
            ></Selector>
          ) : null} */}

          <div
            className={` h-[60%] w-3/4 bg-crate bg-cover bg-center mx-5 ${
              endOfPhase && phase === 1 ? "flex" : "hidden"
            }  items-center justify-center gap-5 shadow shadow-black relative overflow-hidden`}
          >
            <img
              alt=""
              src={require(`../images/materials/wood.jpg`)}
              className={`object-cover object-bottom-center  overflow-hidden absolute w-full h-full top-0 leading-relaxed  brightness-[.5]    `}
            ></img>
            <div
              className={`w-full h-full border-8 border-black/50 absolute flex justify-center items-center gap-3 `}
            >
              {endOfPhase && phase === 1 ? (
                <Item item={treasureChest[treasureChest.length - 1]}></Item>
              ) : null}
            </div>
          </div>

          {/* {endOfPhase ? (
            <Selector
              direction={"right"}
              clickFunction={
                viewedItemIndex > 0
                  ? () => setViewedCrateItemIndex(viewedCrateItemIndex + 1)
                  : null
              }
              active={viewedCrateItemIndex < treasureChest.length - 1}
            ></Selector>
          ) : null} */}
        </div>

        {/* ACTION PAGE */}

        <div
          className={`${
            phase === 2 &&
            !endOfPhase &&
            !choosingTargets &&
            !viewingItems &&
            !choosingTreasureChestItem
              ? "flex"
              : "hidden"
          } flex-row  overflow-y-scroll w-full h-full justify-around items-center gap-y-5  py-5 flex-wrap  `}
        >
          {pocketedItems.length > 0 ? (
            <div className={`h-2/3 w-full flex justify-around items-center `}>
              <Selector
                direction={"left"}
                clickFunction={
                  viewedItemIndex > 0
                    ? () => setViewedItemIndex(viewedItemIndex - 1)
                    : null
                }
                active={viewedItemIndex > 0}
              ></Selector>
              {pocketedItems
                ? pocketedItems.map((item, index) => {
                    return (
                      <Item
                        visible={
                          viewedItemIndex === index &&
                          !choosingTargets &&
                          !choosingTreasureChestItem
                        }
                        item={item}
                        key={index}
                      ></Item>
                    );
                  })
                : null}
              <Selector
                clickFunction={
                  viewedItemIndex < pocketedItems.length - 1
                    ? () => setViewedItemIndex(viewedItemIndex + 1)
                    : null
                }
                active={viewedItemIndex < pocketedItems.length - 1}
              ></Selector>
            </div>
          ) : (
            <Textplate>No Items</Textplate>
          )}
        </div>

        <div
          className={`${
            phase === 2 && endOfPhase && !viewingItems ? "flex" : "hidden"
          } flex-row  overflow-y-scroll w-full h-full justify-around items-center gap-y-5  py-5 flex-wrap  `}
        >
          {activatedItems.length > 0 ? <Textplate>You Used</Textplate> : null}
          {activatedItems.length > 0 ? (
            <div className={`h-2/3 w-full flex justify-around items-center `}>
              <Selector
                direction={"left"}
                clickFunction={
                  viewedItemIndex > 0
                    ? () => setViewedItemIndex(viewedItemIndex - 1)
                    : null
                }
                active={viewedItemIndex > 0}
              ></Selector>
              {activatedItems
                ? activatedItems.map((item, index) => {
                    return (
                      <Item
                        visible={viewedItemIndex === index}
                        item={item["item"]}
                        key={index}
                      ></Item>
                    );
                  })
                : null}
              <Selector
                clickFunction={
                  viewedItemIndex < activatedItems.length - 1
                    ? () => setViewedItemIndex(viewedItemIndex + 1)
                    : null
                }
                active={viewedItemIndex < activatedItems.length - 1}
              ></Selector>
            </div>
          ) : (
            <Textplate>No Items Used</Textplate>
          )}
        </div>
      </div>

      {/* END OF GAME PAGE */}

      <div
        className={`w-full h-[70%]  ${
          winner !== null && !endOfPhase
            ? "portrait:flex landscape:hidden justify-center items-center"
            : "hidden"
        }`}
      >
        <Selector
          direction={"left"}
          clickFunction={
            viewedPlayerIndex > 0
              ? () => setViewedPlayerIndex(viewedPlayerIndex - 1)
              : null
          }
          active={viewedPlayerIndex > 0}
        ></Selector>
        {winner !== null && !endOfPhase && finalUsers !== null
          ? finalUsers.map((currentUser, index) => {
              return (
                <div className={`flex items-center justify-around flex-col`}>
                  <Player
                    user={currentUser}
                    visible={viewedPlayerIndex === index}
                    clientUser={user}
                  ></Player>
                  <Role
                    role={currentUser["role"]}
                    visible={viewedPlayerIndex === index}
                  ></Role>
                </div>
              );
            })
          : null}
        <Selector
          clickFunction={
            viewedPlayerIndex < users.length - 1
              ? () => setViewedPlayerIndex(viewedPlayerIndex + 1)
              : null
          }
          active={viewedPlayerIndex < users.length - 1}
        ></Selector>
      </div>

      {/* LOWER */}

      <div
        className={`w-full h-[15%] border-b-4 border-black/10 bg-black/50 backdrop-blur-[2px]    items-center justify-around  z-30 portrait:flex landscape:hidden`}
      >
        <div className={`h-full w-1/3 flex items-center justify-around`}>
          <div
            className={`w-[50px] aspect-square border-4 border-neutral-300 text-neutral-300 text-2xl ${
              winner === null &&
              phase !== -1 &&
              !endOfPhase &&
              !choosingTreasureChestItem
                ? "flex"
                : "hidden"
            } justify-center items-center  rounded-full`}
            onClick={() => setViewingItems(!viewingItems)}
          >
            <FontAwesomeIcon
              icon={viewingItems ? faBoxOpen : faBox}
            ></FontAwesomeIcon>
          </div>

          <div
            className={`h-full w-[60%] text-3xl ${
              winner !== null && !endOfPhase ? "flex" : "hidden"
            } items-center justify-center text-center ${
              winner === role["team"] ? "text-yellow-400" : "text-white"
            }`}
          >
            {winner === 0
              ? "Pirates Win!"
              : winner === 1
              ? "Mutinists Win!"
              : null}
          </div>
        </div>

        <div
          className={`h-full w-1/3 ${
            winner === null ? "flex" : "hidden"
          }  items-center justify-around`}
        >
          {expeditionItems && phase === 1 && !viewingItems && !endOfPhase ? (
            <Button clickFunction={() => pocketItems(viewedItemIndex)}>
              Pocket
            </Button>
          ) : null}
          {viewingItems ? (
            <Textplate>
              {viewingPocket ? "Pocketed Items" : "Captain's Chest"}
            </Textplate>
          ) : null}
          {phase === 2 &&
          viewedItem &&
          pocketedItems.length > 0 &&
          viewedItem["id"] !== 0 &&
          !viewingItems &&
          !lockedIn ? (
            <Button
              clickFunction={
                (viewedItem["targetType"] === "player" &&
                  viewedItem["playerTargetType"] === "self") ||
                activatedItems.some((i) => i["index"] === viewedItemIndex)
                  ? () =>
                      togglePocketedItem(
                        pocketedItems[viewedItemIndex],
                        null,
                        viewedItemIndex
                      )
                  : (viewedItem["targetType"] === "player" &&
                      viewedItem["playerTargetType"] === "other") ||
                    (viewedItem["targetType"] === "item" &&
                      viewedItem["itemTargetType"] === "player")
                  ? () => chooseTargets()
                  : viewedItem["targetType"] === "item" &&
                    viewedItem["itemTargetType"] === "chest"
                  ? () => chooseTreasureChestItem()
                  : null
              }
            >
              {activatedItems.some((i) => i["index"] === viewedItemIndex)
                ? "Cancel"
                : choosingTargets || choosingTreasureChestItem
                ? "Back"
                : "Use"}
            </Button>
          ) : null}
          {phase === -1 ? (
            <Button clickFunction={() => readyUp()}>
              {readiedUp ? "Unready" : "Ready"}
            </Button>
          ) : null}
        </div>

        <div
          className={`h-full w-1/3 ${
            winner === null ? "flex" : "hidden"
          } items-center justify-around`}
        >
          <div
            className={`w-[50px] aspect-square border-4 border-neutral-300 text-neutral-300 text-2xl ${
              winner === null ? "flex" : "hidden"
            } justify-center items-center  rounded-full`}
            onClick={() => setHideScreen(!hideScreen)}
          >
            <FontAwesomeIcon
              icon={hideScreen ? faEye : faEyeSlash}
            ></FontAwesomeIcon>
          </div>

          {phase !== 1 ? (
            <div
              className={`w-[50px] aspect-square border-4 border-neutral-300 text-neutral-300 text-2xl ${
                winner === null && phase !== -1 ? "flex" : "hidden"
              } justify-center items-center  rounded-full`}
              onClick={() => lockIn()}
            >
              <FontAwesomeIcon
                icon={lockedIn ? faLock : faLockOpen}
              ></FontAwesomeIcon>
            </div>
          ) : null}
        </div>

        {/* <div
          className={`text-2xl w-[25%] h-full ${
            winner !== null && (phase === "lunar" || phase === "solar")
              ? "hidden"
              : phase !== -1
              ? "flex"
              : "hidden"
          } justify-center items-center text-center border-box 
          ${time <= 15 ? "text-red-500" : "text-white"}  
          border-l-4 border-black/20`}
        >
          {`${Math.floor(time / 60)}`.padStart(2, 0)}:
          {`${time % 60}`.padStart(2, 0)}
        </div> */}
      </div>

          <Information user={user} data={data} page={"game"} clickFunction={returnToLobby}></Information>
      {/* <div
        className={`portrait:hidden landscape:flex w-full h-full justify-center items-center `}
      >
        <div className={`flex w-2/3 h-full justify-center items-center gap-1`}>
          <Selector
            direction={"left"}
            clickFunction={() =>
              setViewedRole(
                viewedRole === 0
                  ? Object.keys(data["roles"]).length - 1
                  : viewedRole - 1
              )
            }
          ></Selector>

          {data &&
            Object.values(data["roles"]).map((currentRole, index) => {
              return (
                <Role
                  role={currentRole}
                  visible={viewedRole === index}
                  highlight={currentRole["name"] === role["name"]}
                  key={index}
                ></Role>
              );
            })}

          <Selector
            direction={"right"}
            clickFunction={() =>
              setViewedRole(
                viewedRole === Object.keys(data["roles"]).length - 1
                  ? 0
                  : viewedRole + 1
              )
            }
          ></Selector>
        </div>
        <div className={`flex flex-col justify-around h-full`}>
          <Textplate>Room {room}</Textplate>
          {host === username ? (
            <Button clickFunction={returnToLobby}>End</Button>
          ) : null}
        </div>
      </div> */}

      <div
        className={`absolute h-full bottom-0 w-full z-20 bg-main bg-center bg-cover ${
          hideScreen
            ? "portrait:flex landscape:hidden justify-center items-center"
            : "hidden"
        }`}
      >
        <img
          src={require(`../images/logos/mutiny.png`)}
          alt=""
          className={`w-full max-w-[512px] top-0 h-auto absolute object-contain`}
        ></img>
      </div>

      <div
        className={`absolute h-full w-full bottom-0 bg-transition bg-center bg-cover ${
          phase === "reconnect"
            ? "portrait:flex landscape:hidden justify-center items-center flex-col"
            : "hidden"
        } z-40 gap-1`}
      >
        <Textplate>You will be returned to the game next phase.</Textplate>
        <Role role={role}></Role>
      </div>
    </div>
  );
}
