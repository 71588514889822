import { useEffect, useState } from "react";

export default function Choice(props) {
  const {
    clickFunction,
    colour,
    type = "card",
    id = 1,
    currentSelection,
  } = props;

  // const [currentColour, setCurrentColour] = useState(
  //   "bg-gradient-to-t from-slate-300 to-slate-50"
  // );

  // useEffect(() => {
  //   switch (colour) {
  //     default:
  //       setCurrentColour("bg-black");
  //       break;
  //     case "red":
  //       setCurrentColour("bg-gradient-to-t from-rose-900 to-red-700");
  //       break;
  //     case "orange":
  //       setCurrentColour("bg-gradient-to-t from-orange-800 to-orange-600");
  //       break;
  //     case "yellow":
  //       setCurrentColour("bg-gradient-to-t from-yellow-700 to-yellow-500");
  //       break;
  //     case "green":
  //       setCurrentColour("bg-gradient-to-t from-lime-700 to-lime-500");
  //       break;
  //     case "blue":
  //       setCurrentColour("bg-gradient-to-t from-sky-700 to-sky-500");
  //       break;
  //     case "indigo":
  //       setCurrentColour("bg-gradient-to-t from-indigo-700 to-indigo-500");
  //       break;
  //     case "violet":
  //       setCurrentColour("bg-gradient-to-t from-fuchsia-900 to-fuchsia-700");
  //       break;
  //     case "white":
  //       setCurrentColour("bg-gradient-to-t from-slate-300 to-slate-50");
  //       break;
  //     case "lightGray":
  //       setCurrentColour("bg-gradient-to-t from-gray-500 to-gray-300");
  //       break;
  //     case "darkGray":
  //       setCurrentColour("bg-gradient-to-t from-gray-700 to-gray-500");
  //       break;
  //     case "black":
  //       setCurrentColour("bg-gradient-to-t from-slate-900 to-slate-700");
  //       break;
  //     case "lightBrown":
  //       setCurrentColour("bg-gradient-to-t from-amber-800 to-amber-700");
  //       break;
  //     case "brown":
  //       setCurrentColour("bg-gradient-to-t from-amber-900 to-amber-800");
  //       break;
  //     case "darkBrown":
  //       setCurrentColour("bg-gradient-to-t from-amber-950 to-amber-900");
  //       break;
  //   }
  // }, [colour]);

  const cards = {
    1: "bg-fire",
    2: "bg-water",
    3: "bg-trees",
  };

  const icons = {
    1: "bg-skull",
    2: "bg-flower",
    3: "bg-hawk",
  };

  return (
    // <div
    //   className={`w-[300px] h-[100px] max-w-[1/2]  relative flex items-center justify-center`}
    //   onClick={clickFunction}
    // >
    <div
      className={` ${
        type === "card"
          ? "w-[175px] h-[60px] max-w-[1/2] border-4 "
          : "w-[75px] aspect-square rounded-full outline outline-4 outline-black/50 -outline-offset-4"
      }   ${
        type === "card" ? cards[id] : icons[id]
      } bg-cover bg-left rounded flex relative justify-end items-center  ${
        currentSelection === id
          ? "border-yellow-200/75 outline-yellow-200/75"
          : "border-black/50 outline-black/50"
      } shadow-black shadow-lg transition-all`}
      onClick={clickFunction}
    ></div>
    // </div>

    // <div className="w-12 h-12 flex items-center justify-center shrink-0  ">
    //   <button
    //     className={`${currentColour}  border-4 ${
    //       selected ? "w-12 h-12" : "w-10 h-10"
    //     } shadow shadow-black/60 border-black  rounded-full relative transition-all shrink-0 overflow-hidden`}
    //     onClick={onClick}
    //   >
    //     {[1, 2, 3, 4, 5, 6].map((num, index) => {
    //       return (
    //         <img
    //         key={index}
    //           alt=""
    //           className={
    //             `${
    //             design === num ? "absolute" : "hidden"
    //           } h-full w-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 brightness-0 invert`}
    //           src={require("../images/shield-designs/shield-design-" +
    //             num +
    //             ".png")}
    //         ></img>
    //       );
    //     })}
    //   </button>
    // </div>
  );
}
