import { useCallback, useEffect, useRef, useState } from "react";
import { socket } from "../socket";
import Button from "../components/Button";
import Input from "../components/Input";
import Choice from "../components/Choice";
import Player from "../components/Player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faMasksTheater,
  faPaintBrush,
} from "@fortawesome/free-solid-svg-icons";
import Selector from "../components/Selector";
import Role from "../components/Role";
import Information from "../components/Information";
import { gsap } from "gsap/gsap-core";
import { useGSAP } from "@gsap/react";

export default function Main(props) {
  const { user, setCustomization, setUsername, debug, data } = props;

  const [roomInput, setRoomInput] = useState("");
  // const [usernameInput, setUsernameInput] = useState(user["username"]);
  const [roomInputVisible, setRoomInputVisible] = useState(false);
  // const [card, setCard] = useState(parseInt(user["customization"]["card"]));
  // const [icon, setIcon] = useState(parseInt(user["customization"]["icon"]));

  const createRoom = useCallback(() => {
    socket.emit("create", {
      username: user["username"],
      customization: {
        card: user["customization"]["card"],
        icon: user["customization"]["icon"],
      },
    });
  }, [user]);

  const logoRef = useRef(null);
  useEffect(() => {
    if (debug) {
      socket.emit("debug", {
        username: user["username"],
        customization: {
          card: user["customization"]["card"],
          icon: user["customization"]["icon"],
        },
      });
    }

    // gsap.fromTo(
    //   logoRef.current,
    //   {
    //     y: -5,
    //   },
    //   {
    //     y: 5,
    //     duration: 3,
    //     repeat: -1,
    //     yoyo: true,
    //     yoyoEase: true
    //   }
    // );

    // let introMusic = new Audio(require("../sounds/mutiny-intro.mp3"));
    // let playResult = introMusic.play();
    // playResult.catch((e) => {
    //   console.log("no interaction");
    // });
  }, [debug, user]);

  useGSAP(
    () => {
      gsap.fromTo(
        logoRef.current,
        { y: -300 },
        { y: 0, duration: 1, ease: "power1.out" }
      );

      gsap.fromTo(
        logoRef.current,
        {
          rotateZ: -1,
        },
        {
          rotateZ: 1,
          duration: 5,
          repeat: -1,
          yoyo: true,
          yoyoEase: true,
        }
      );
    },
    { dependencies: [logoRef] }
  );

  const joinRoom = useCallback(
    (room) => {
      if (room.length === 4) {
        console.log("Attempting to join room " + room);
        socket.emit("join", {
          username: user["username"],
          room,
          customization: {
            card: user["customization"]["card"],
            icon: user["customization"]["icon"],
          },
        });
      }

      setRoomInputVisible(false);
      setRoomInput("");
    },
    [user]
  );

  const showRoomCode = useCallback(() => {
    setRoomInputVisible(true);
  }, []);

  return (
    <div className={`w-full h-full`}>
      <div
        className={` ${
          debug ? "flex" : "portrait:flex landscape:hidden"
        } bg-main bg-cover bg-[center] bg-no-repeat relative flex-col items-center justify-end w-screen h-screen  
        z-10 select-none `}
      >
        <img
          src={require(`../images/logos/mutiny-beta.png`)}
          alt=""
          className={`w-full max-w-[75%] top-1 h-auto absolute object-contain`}
          ref={logoRef}
        ></img>

        <div
          className={` w-full  flex flex-col justify-center gap-5 pb-[30%] items-center`}
        >
          <Input
            placeholder="ROOM"
            type="number"
            maximum={4}
            id="roomCode"
            value={roomInput}
            setValue={setRoomInput}
            display={roomInputVisible ? "block" : "none"}
            focus={roomInputVisible && roomInput === ""}
            method={joinRoom}
            bare={true}
          />
          <div className={`w-full flex justify-center gap-5  items-center`}>
            <Button clickFunction={createRoom}>HOST</Button>

            {debug ? (
              <Button
                // onClick={showRoomCode}
                clickFunction={() => joinRoom("1234")}
                // display={roomInputVisible ? "none" : "flex"}
              >
                JOIN
              </Button>
            ) : (
              <Button
                clickFunction={showRoomCode}
                // onClick={() => joinRoom("1234")}
                // display={roomInputVisible ? "none" : "flex"}
              >
                JOIN
              </Button>
            )}
          </div>
        </div>
      </div>

      <Information
        data={data}
        setCustomization={setCustomization}
        setUsername={setUsername}
        user={user}
        page={"main"}
      ></Information>
    </div>
  );
}
