// import { useCallback, useEffect, useState } from "react";

import { useEffect, useRef } from "react";
import Player from "./Player";
import { gsap } from "gsap/gsap-core";

export default function Role({
  role,
  visible = true,
  highlight = false,
  username = null,
}) {
  const {
    name,
    team,
    action,
    targets,
    cooldown,
    image,
    punish,
    punishTeam,
    knows,
    description,
  } = role;

  const phaseStyles = {
    0: " rotate-[0deg]", //top-[-2%] left-[20%]
    1: " rotate-[45deg]", //top-[-2%] right-[20%]
    2: " rotate-[90deg]", //top-[25%] right-[-4%]
    3: " rotate-[135deg]", //bottom-[25%] right-[-4%]
    4: "rotate-[180deg]", //bottom-[-2%] right-[20%]
    5: " rotate-[225deg]", //bottom-[-2%] left-[20%]
    6: " rotate-[270deg]", //bottom-[25%] left-[-4%]
    7: " rotate-[315deg]", //top-[25%] left-[-4%]
  };

  const moonStyles = {
    0: " rotate-[0deg]", //top-[-2%] left-[20%]
    1: " rotate-[-45deg]", //top-[-2%] right-[20%]
    2: " rotate-[-90deg]", //top-[25%] right-[-4%]
    3: " rotate-[-135deg]", //bottom-[25%] right-[-4%]
    4: "rotate-[-180deg]", //bottom-[-2%] right-[20%]
    5: " rotate-[-225deg]", //bottom-[-2%] left-[20%]
    6: " rotate-[-270deg]", //bottom-[25%] left-[-4%]
    7: " rotate-[-315deg]", //top-[25%] left-[-4%]
  };

  const roleNames = {
    0: "Warlock",
    1: "Witch",
    2: "Queen",
    3: "King",
    6: "Scouts",
  };

  const teamNames = {
    0: "Secret Service",
    1: "Anarchist",
  };

  const roleRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      roleRef.current,
      {
        rotateZ: -1,
      },
      {
        rotateZ: 1,
        duration: 5,
        repeat: -1,
        yoyo: true,
        yoyoEase: true
      }
    );

    gsap.fromTo(
      roleRef.current,
      {
        y: -5,
      },
      {
        y: 5,
        duration: 3,
        repeat: -1,
        yoyo: true,
        yoyoEase: true
      }
    );
  }, []);

  // useEffect(() => {
  //   console.log("name", name)
  //   console.log("knows", knows)
  //   console.log(roleNames[knows])
  //   knows ? console.log("Knows the " + roleNames[knows] + "."): console.log("nope")
  // }, [knows])

  return (
    <div
      className={`relative  w-[200px] h-[275px] max-h-[100%] rounded-md bg-cover bg-center  shadow-lg  shadow-black    transition-all select-none   select-none   flex-col items-center justify-end overflow-hidden ${
        visible ? "flex" : "hidden"
      }`}
      ref={roleRef}
    >
      {/* <div className={`absolute w-full h-full rounded-full bg-bronze ${moonStyles[moon]} transition-all duration-1000`}>
        {[0, 1, 2, 3, 4, 5, 6, 7].map((moonPhase, index) => {
          return (
            <div key={index} className={`w-full h-full absolute ${phaseStyles[moonPhase]}`}>
              <img
                alt=""
                src={require(`../images/symbols/${moonPhase}.png`)}
                className={`absolute  rounded-full w-[8%] top-[1%] aspect-square left-1/2 -translate-x-1/2 `}
              ></img>
            </div>
          );
        })}
      </div> */}

      <img
        alt=""
        src={require(`../images/roles/${image ? image : "hitman.jpg"}`)}
        className={`object-cover object-bottom-center  overflow-hidden absolute w-full h-full top-0 leading-relaxed     `}
      ></img>
      {/* <div className="object-cover object-top overflow-hidden absolute top-[10%] w-[80%]  h-[80%] leading-relaxed  border-4 border-black/75 rounded-full rounded-tr-full   ">

      </div> */}

      {/* <div
        className={` h-full w-fit px-2 mb-1 relative rounded-full flex items-center justify-center text-xl text-white bg-black/50  backdrop-blur-[1px] `}
      >
        {teamNames[role["team"]]}
      </div> */}
      <div
        className={`overflow-hidden ${
          highlight ? "text-yellow-200" : "text-white"
        }   leading-relaxed   w-full h-[10%] bg-black/75  backdrop-blur-[1px] text-center text-2xl bottom-[38%]`}
      >
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </div>
      <div
        className={`overflow-hidden   w-full h-[100px] text-white/85 bg-black/50 border-0 backdrop-blur-[2px] border-black leading-relaxed flex justify-center items-center  px-3 text-md text-center   leading-[1] text-balance`}
      >
        {description}
        {/* {action === "poison" ? "Shoot" : action.charAt(0).toUpperCase() + action.slice(1)} {targets} {action === "return" ? "exiled " : ""}player
        {targets !== 1 && "s"}{action === "poison" ? " with a poisoned arrow" : ""}.{" "}
        {punish
          ? punishTeam !== undefined
            ? `If ${targets === 1 ? "that" : "either"} player is  ${
                teamNames[punishTeam]
              }, you will be ${punish.toLowerCase()}${
                punish === "exile" ? "d" : "ed"
              }. `
            : null
          : null}
        {knows !== undefined ? "Knows the " + roleNames[knows] + "." : null} */}
        {/* <div
          className={`absolute w-full h-[15%]  top-1 left-1/2 -translate-x-1/2 flex justify-center items-center gap-3`}
        >
          {[...Array(cooldown)].map((value, index) => {
            return (
              <div key={index}
                className={` h-3/4 aspect-square rounded-full bg-white`}
              ></div>
            );
          })}
        </div> */}
      </div>

      <div
        className={`absolute -top-3 w-full -left-5  ${
          username === null ? "hidden" : "flex"
        }`}
      >
        {username !== null ? (
          <Player username={username} selectable={false}></Player>
        ) : null}
      </div>

      {/* <div className={`absolute w-1/4 aspect-square -top-1 -right-1 `}>
      <img 
        alt=""
        src={require(`../images/symbols/5.png`)}
        className={`object-contain  overflow-hidden  absolute   `}></img>
        <div className={`absolute top-1/2 -translate-y-1/2 -left-1 w-full flex justify-center items-center text-3xl`}>{cooldown}</div>
      </div> */}

      {/* {cooldown} */}
      {/* </div> */}
      <div
        className={`w-full h-full absolute outline outline-4 -outline-offset-4 outline-black/50 rounded-md`}
      ></div>
    </div>
  );
}
