export default function Button(props) {
  const { children, clickFunction, type = "text", selected, } = props;
  // const style = {
  //   display: display === "none" ? "none" : display,
  // };
  return (
    <div
      className={`${
        type === "text"
          ? "w-[150px] h-[80px]  rounded-md"
          : "rounded-full w-[75px] aspect-square"
      } ${
        selected
          ? "border-yellow-200/50 text-yellow-200 "
          : "text-orange-400 border-orange-400/50"
      } relative transition-all bg-gradient-to-tr from-neutral-950/75 via-neutral-900/75 to-neutral-950/95 border-4 border-orange-400/75  text-4xl flex justify-center items-center shadow-lg shadow-black `}
      onClick={clickFunction}
    >{children}
      {/* <img
        alt=""
        src={require(`../images/materials/sandstone.jpg`)}
        className={`object-cover object-bottom-center  overflow-hidden absolute rounded w-full h-full top-0 leading-relaxed  brightness-[.5]    `}
      ></img> */}
    </div>

  );
}
